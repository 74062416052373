@use "../../styles/variables/mixins" as *;

.link {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  text-align: center;
  margin-top: 12rem;
  color: #FFFFFF;

  @include mq(laptop) {
    flex-direction: row;
    gap: 3rem;
    margin-top: 15.8rem;
  }

  &-content-web {
    background-image: url("../../assets/home/mobile/image-web-design.jpg");

    h2 {
      padding-top: 9rem;

      @include mq(tablet) {
        padding-top: 5.3rem;
      }

      @include mq(laptop) {
        padding-top: 27.3rem;
      }
    }

    @include mask-image;
    @include mq(tablet) {
      background-image: url("../../assets/home/tablet/image-web-design.jpg");
      height: 20rem;
    }
    @include mq(laptop) {
      background-image: url("../../assets/home/desktop/image-web-design-large.jpg");
      height: 64rem
    }
  }

  &-content-app {
    background-image: url("../../assets/home/mobile/image-app-design.jpg");
    @include mask-image;
    @include mq(tablet) {
      background-image: url("../../assets/home/tablet/image-app-design.jpg");
      height: 20rem;
    }
    @include mq(laptop) {
      background-image: url("../../assets/home/desktop/image-app-design.jpg");
      height: 30.8rem;
    }
  }

  &-content-graphic {
    background-image: url("../../assets/home/mobile/image-graphic-design.jpg");
    @include mask-image;
    @include mq(tablet) {
      background-image: url("../../assets/home/tablet/image-graphic-design.jpg");
      height: 20rem;
    }
    @include mq(laptop) {
      background-image: url("../../assets/home/desktop/image-graphic-design.jpg");
      height: 30.8rem;
    }
  }
  

  &-content {
    position: relative;
    z-index: 2;

    &-title {
      padding-top: 9rem;

      @include mq(tablet) {
        padding-top: 5.3rem
      }

      @include mq(laptop) {
        padding-top: 10.7rem;
      }
    }

    h2 {
      margin-bottom: 1.2rem;

      @include mq(tablet) {
        margin-bottom:2.4rem
      }
    }

    p {
      display: flex;
      gap: 1.6rem;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
      text-transform: uppercase;
      line-height: 2.2rem;
      letter-spacing: 5px;
    }
  }

  &-cut {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    h2 {
      padding-top: 9rem;

      @include mq(tablet) {
        padding-top: 5.3rem;
      }

      @include mq(laptop) {
        padding-top: 10.7rem;
      }
    }
  }

  // For other pages
  &-size {
     @include mq(laptop) {
       width: 54.1rem;
     }
     &-web {
       background-image: url("../../assets/home/desktop/image-web-design-small.jpg");
       @include mask-image;
       
       @include mq(laptop) {
        height: 30.8rem;
      }
      }
      
      &-graphic {
        background-image: url("../../assets/home/desktop/image-graphic-design.jpg");
        @include mask-image;

        @include mq(laptop) {
          height: 30.8rem;
        }
     }
  }
}