@use '../../styles/variables/mixins' as *;

.about {
  @include mq(tablet) {
    margin-bottom: 12rem;
  }

  @include mq(laptop) {
    position: relative;
    z-index: 2;
  }
  
  &-header {
    background-color: #E7816B;
    background-image: url("../../assets/about/mobile/bg-pattern-hero-about-mobile.svg");
    background-position: -50rem 18rem;
    background-repeat: no-repeat;

    @include mq(tablet) {
      border-radius: 15px;
      background-image: url("../../assets/about/desktop/bg-pattern-hero-about-desktop.svg");
      background-position: -15rem -12rem;
    }

    @include mq(laptop) {
      display: flex;
      flex-direction: row-reverse;
      height: 48rem;
      background-position: 0rem -16rem;
    }

    &-img {
        img {
        @include mq(tablet) {
          border-radius: 15px 15px 0 0;
        }

        @include mq(laptop) {
          border-radius: 0 15px 15px 0;
        }
      }
    }
  
    h1 {
      text-transform: initial;
      margin-bottom: 2.4rem;

      @include mq(laptop) {
        margin-bottom: 3.2rem;
      }
    }

    &-text {
      text-align: center;
      color: #FFFFFF;
      padding: 8rem 2.4rem;

      @include mq(tablet) {
        padding: 6.4rem 5.8rem;
      }

      @include mq(laptop) {
        flex: 2;
        padding: 13.5rem 8.2rem 13.5rem 9.5rem;
        width: 54rem;
        text-align: left;
      }
    }
  }
}

.infos {

  @include mq(laptop) {
    position: relative;
    z-index: 2;
  }
  
  &-content {
    background-color: #FDF3F0;
    background-image: url("../../assets/shared/desktop/bg-pattern-three-circles.svg");
    background-position: 0 32rem;
    background-repeat: no-repeat;

    @include mq(tablet) {
      border-radius: 15px;
      background-position: 9rem 10rem;
    }

    @include mq(laptop) {
      display: flex;
      background-position: 34rem 6rem;
    }

    &-reverse {
      @include mq(laptop) {
        flex-direction: row-reverse;
        background-position: 0rem 6rem;
      }
    }

    &-img {
      img {

        @include mq(tablet) {
          border-radius: 15px 15px 0 0;
        }

        @include mq(laptop) {
          border-radius: 15px 0 0 15px;
        }
      }

      &-reverse {
        img {
          @include mq(laptop) {
            border-radius: 0 15px 15px 0;
          }
        }
      }
    }

    h2 {
      color: #E7816B;
      text-transform: initial;
      margin-bottom: 2.4rem;
      font-size: 32px;
      line-height: 36px;
    }

    &-text {
      text-align: center;
      padding: 8rem 2.4rem;

      @include mq(tablet) {
        padding: 6.8rem 5.8rem 6.8rem 5.9rem;
      }

      @include mq(laptop) {
        flex: 2;
        text-align: left;
        width: 44.5rem;
        padding: 15.4rem 9.6rem 15.4rem 9.4rem;
      }
    }
  }
}