@use "../variables/mixins" as *;

:root {
  font-size: 10px;
  font-family: 'Jost', sans-serif;
}

body {
  background-color: #fff;
}

h1,h2,h3,h4, button {
  font-weight: 500;
  text-transform: uppercase;
}

h1 {
  font-size: 3.2rem;
  line-height: 1.6rem;

  @include mq(tablet) {
    font-size: 4.8rem;
    line-height: 4.8rem;
  }
}

h2 {
  font-size: 2.8rem;
  line-height: 3.6rem;
  letter-spacing: 1.4px;

  @include mq(tablet) {
    font-size: 4rem;
    line-height: 4.8rem;
    letter-spacing: 2px;
  }
}

h3 {
  font-size: 2rem;
  line-height: 2.6rem;
}

h4 {
  font-size: 1.5rem;
  line-height: 2.2rem;
  letter-spacing: 5px;
}

h5 {
  font-size: 3.2rem;
  line-height: 3.6rem;
  font-weight: 500;
  margin: 0;

  @include mq(tablet) {
    font-size: 4rem;
    line-height: 4rem;
  }
}

.container {
  position: relative;
  max-width: 111rem;
  margin: 0 auto;
  padding: 0 2.4rem;

  @include mq(tablet) {
    padding: 0 3.9rem 0 4rem ;
  }

  @include mq(desktop) {
    padding: 0
  }
}

.background {
  @include mq(laptop) {
    position: relative;
    max-width: 144rem;
    margin: auto;

    &-about {
      overflow: hidden;
    }
  }
}

.new-container {
  max-width: 111rem;
  
  @include mq(tablet) {
    margin: 0 4rem 0 3.9rem;
  }
  
  @include mq(desktop) {
    margin: 0 auto;
  }
}

.logo {
  width: 40.4rem;
  height: 5.4rem;
}

.text {
  font-size: 1.6rem;
  line-height: 2.6rem;
  font-weight: 400;
}

.small-text {
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: 400;

  @include mq(tablet) {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
}

.li_nk {
  font-size: 1.4rem;
  line-height: 1.4rem;
  letter-spacing: 2px;

  &:hover {
    text-decoration: underline;
  }
}

.button {
  width: 15.2rem;
  height: 5.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  background-color: #FFFFFF;
  border-radius: 8px;
  color: #333136;
  font-size: 1.5rem;
  line-height: 2.2rem;
  letter-spacing: 1px;
  font-weight: 500;

  &:hover {
    background-color: #FFAD9B;
    color: #FFFFFF;
  }

  &-orange {
    background-color: #E7816B;
    color: #FFFFFF;
  }
}
