@use "../../styles/variables/mixins" as *;

.hero {
  @include bg-hero;
  background-image: url('../../assets/home/desktop/bg-pattern-hero-home.svg');
  background-position-y: 50%;
  height: 84.3rem;
  overflow: hidden;

  @include mq(tablet) {
    background-position-x: 16rem;
  }

  @include mq(laptop) {
    height: 64rem;
    background-position-x: 48rem;
    z-index: 2;
  }

  &-content {
    color: #FFFFFF;
    padding-top: 8rem;
    text-align: center;

    @include mq(tablet) {
      margin: 0 5.8rem;
      padding-top: 6rem;
    }

    @include mq(laptop) {
      display: flex;
      text-align: left;
      margin: 0;
      padding: 0;
      gap: 6rem;
    }

    &-text {

      @include mq(laptop) {
        padding: 14.5rem 0 0 5.6rem; 
        max-width: 54rem;
      }

      @include mq(desktop) {
        padding: 14.3rem 0 0 9.5rem;
        max-width: 57rem;
      }

      h1 {
        text-transform: inherit;
      }

      p {
        margin: 1.4rem 0 2.4rem 0;

        @include mq(tablet) {
          margin: 2.4rem 3.9rem 1.9rem 3.8rem;
        }

        @include mq(laptop) {
          margin: 2.1rem 0 4rem 0;
        }
      }

      div.button {
        margin: auto;

        @include mq(laptop) {
          margin: 0;
        }
      }
    }

    &-img {
      position: absolute;
      width: max-content;
      left: 0;
      right: 0;
      top: 47.2rem;
      margin: 0 auto;
            
      @include mq(tablet) {
        filter: drop-shadow(20px -40px 80px rgba(93, 2, 2, 0.497569));
        top: 46.2rem;
      }

      @include mq(laptop) {
        position: relative;
        top: 15rem;
      }
    }
  }
}

// Work styles
.work {
  margin-top: 12rem;
  
  @include mq(laptop) {
    position: relative;
    margin-top: 16rem;
  }

  &-container {
    display: flex;
    flex-direction: column;
    gap: 8rem;
    
    @include mq(tablet) {
      gap: 3.2rem;
    }
    
    @include mq(laptop) {
      flex-direction: row;
      gap: 3rem;
      z-index: 2;
    }
  }

  // To put the linear background on the images
  &-img {
    &-passionate {
       &:before {
         @include bg-image;
        transform: matrix(-1, 0, 0, 1, 0, 0);
      }
    }

    &-resourceful {
      &:before {
        @include bg-image;
        transform: matrix(0, 1, 1, 0, 0, 0);
      }
    }

    &-friendly {
      &:before {
        @include bg-image;
        transform: matrix(0, -1, -1, 0, 0, 0);
      }
    }
  }

  &-content {
    text-align: center;

    @include mq(tablet) {
      display: flex;
      align-items: center;
      gap: 4.8rem;
      text-align: left;
    }

    @include mq(laptop) {
      flex-direction: column;
      text-align: center;
    }

    &-img {
      position: relative;
      width: fit-content;
      margin: auto;
    }
    
    &-text {
      margin-top: 4.8rem;

      @include mq(tablet) {
        flex: 2;
        margin-top: 0;
      }

      h3 {
        letter-spacing: 5px;
        margin-bottom: 3.2rem;

        @include mq(tablet) {
          margin-bottom: 1.6rem;
        }

        @include mq(laptop) {
          margin-bottom: 3.2rem;
        }
      }
    }
  }
}

// Add background image
  .background-image {

    display: none;

    @include mq(laptop) {
      display: block;
      position: absolute;
      z-index: 1;

      &-bottom {
        transform: rotate(180deg);
        top: 12.4rem;
        right: 0;
      }

      &-top {
        top: 32rem;
      }

      &-card {
        top: 14rem;
      }

      &-about {
        top: 158rem;
        right: -38rem;
      }
    }
  }
  