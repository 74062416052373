// To setup Media Queries
@mixin mq($breakpoint) {
  @if $breakpoint == tablet {
    @media (min-width: 768px) {
      @content;
    }
  }
  @if $breakpoint == laptop {
    @media (min-width: 1024px) {
      @content;
    }
  }
  @if $breakpoint == desktop {
    @media (min-width: 1200px) {
      @content;
    }
  }
}

// Mask Link image
@mixin mask-image {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  height: 25rem;
  border-radius: 1.5rem;
  z-index: 2;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #000000;
    border-radius: 1.5rem;
    opacity: 0.5;
    z-index: 1;
  }

  &:hover {
    &::before {
    content: "";
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #E7816B;
    opacity: 0.8;
    z-index: 1;
    }
  }
}

// Background images
@mixin bg-image {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.2;
  border-radius: 50%;
  background: linear-gradient(90deg, rgba(93, 2, 2, 0.0001) 0%, rgba(93, 2, 2, 0.497569) 100%);
}

@mixin bg-hero {
  position: relative;
  background-color: #E7816B;
  background-repeat: no-repeat;
  
  @include mq(tablet) {
    margin: 0 3.9rem 0 4rem;
    border-radius: 1.5rem;
  }

  @include mq(desktop) {
    margin: auto;
  }
}